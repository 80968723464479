import './App.css';

export function CurrentProjects() {
    return (
        <>
            <LanguageLearningApp />
        </>
    );
}

function LanguageLearningApp() {
    return (
        <div>
            <h1>Language Learning App with Java</h1>
            <p>
                Inspired by my efforts to study Spanish before leaving for my study abroad in Argentina,
                I'm working on a Spanish learning application in Java. The app will be a flashcards app, similar
                to Anki, but will have structured paths to assimilate the user into new grammatical structures and
                vocabulary, much in the way of DuoLingo.
            </p>
            <p>
                I am using Java with the Swing library for GUI.
            </p>
            <p>
                <i>Skills: </i>Java, Object-Oriented Programming, Spanish
            </p>
        </div>
    )
}