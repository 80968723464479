import './Enter.css'
import { useState } from 'react';
import { ChoosePage, ListElement } from './menu';
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<Start page_name='start' />}/>
        <Route path='home' element={<Start page_name='Home' />} />
        <Route path='home/aboutme' element={<Start page_name='About Me' />} />
        <Route path='portfolio' element={<Start page_name='Portfolio' />} />
        <Route path='portfolio/rsa' element={<Start page_name='RSA Encryption' />} />
        <Route path='portfolio/nicosantamaria' element={<Start page_name='Nico Santamaria' />} />
        <Route path='portfolio/syndromedecoder' element={<Start page_name='Syndrome Decoder' />} />
        <Route path='portfolio/nimplayer' element={<Start page_name='Nim Player' />} />
        <Route path='portfolio/projecteuler' element={<Start page_name='Project Euler' />} />
        <Route path='currentprojects' element={<Start page_name='Current Projects' />} />
        <Route path='currentprojects/collegerankings' element={<Start page_name='College Rankings' />} />
        <Route path='blog' element={<Start page_name='Blog' />} />
      </Routes>
    </BrowserRouter>
  )
}


function Start( {page_name} ) {
  const [name, setName] = useState(page_name);
  const [show, changeShow] = useState(false);

  function handleClick(newName) {
    setName(newName);
  }

  function handleClickShow() {
    changeShow(!show);
  }

  if (name != 'start') {
    return (
      <>
        <body>
          <div id='header'>
            <h1><i>NICO SANTAMARIA</i></h1>
            <b>
              Mamaroneck, NY 10543&emsp;&emsp;&emsp;&emsp;&emsp;
              Contact Me: <a href='https://www.linkedin.com/in/nico-santamaria/'>LinkedIn</a>
            </b>
          </div>
          <MainPage
            name={name}
            handleClick={handleClick}
            show={show}
            handleClickShow={handleClickShow} 
          />
        </body>
      </>
    );
  } else {
    return (
      <Banner handleClick={handleClick}/>
    )
  }
}

function Banner ( {handleClick} ) {
  return (
      <body id='banner_body'>
        <div id='banner_background'>
          <div>
            <div class='container' id='banner_container'>
                <BannerImage id='mathematics' title='Mathematics' />
                <BannerImage id='philosophy' title='Philosophy' />
                <BannerImage id='computer_science' title='Computer Science' />
            </div>
            <div class='container' id='enter_menu_container'>
              <div class='enter_menu'>
                <h2>Enter to:</h2>
              </div>
              <EnterButton value='Home' onElemClick={() => handleClick('Home')} />
              <EnterButton value='Portfolio' onElemClick={() => handleClick('Portfolio')} />
              <EnterButton value='Current Projects' onElemClick={() => handleClick('Current Projects')} />
              <EnterButton value='Blog' onElemClick={() => handleClick('Blog')} />
            </div>
          </div>
        </div>
      </body>
  )
}

function BannerImage({title, id}) {
  return (
    <div id={id}>
      <div>
        <h1>{title}</h1>
      </div>
    </div>
  )
}

function EnterButton ( {value, onElemClick}) {
  return (
    <div class='enter_menu enter_button' onClick={onElemClick}>
      <h2>{value}</h2>
    </div>
  );
}


function MainPage( {name, handleClick, show, handleClickShow} ) {
  return (
    <div class='container' id='main_container'>
      <div id='list_container'>
        <ListElement
          selected={[
            'Home',
            'Introduction',
            'About Me'
          ].includes(name)}
          value={'Home'}
          onElemClick={() => handleClick('Home')}
          handleClick={handleClick}
        />
        <ListElement
          selected={[
            'Portfolio',
            'Syndrome Decoder',
            'Project Euler',
            'Nico Santamaria',
            'Nim Player',
            'RSA Encryption',
            'College Rankings'
          ].includes(name)}
          value={'Portfolio'}
          onElemClick={() => handleClick('Portfolio')}
          handleClick={handleClick}
        />
        <ListElement
          selected={[
            'Current Projects',
          ].includes(name)}
          value={'Current Projects'}
          onElemClick={() => handleClick('Current Projects')}
          handleClick={handleClick}
        />
        <ListElement
          selected={[
            'Blog'
          ].includes(name)}
          value={'Blog'}
          onElemClick={() => handleClick('Blog')}
          handleClick={handleClick}
        />
      </div>
      <div class='homepage'>
        <ChoosePage page_name={name} show={show} handleClickShow={handleClickShow}/>
      </div>
    </div>
  );
}