import './App.css';


export function Portfolio( {onElemClick, show}) {
    return (
        <>
            <CollegeRankings onElemClick={onElemClick} show={show} />
            <RSA />
            <NicoSantamaria />
            <SyndromeDecoder />
            <NimPlayer />
            <ProjectEuler />
        </>
    );
}


export function CollegeRankings({ onElemClick, show }) {
    return (
        <div>
            <Header title='College Rankings 2024' source_code='https://github.com/NicoSantamaria/College-Rankings-2024/blob/main/rankings.r' />
            <p>
                Current popular college rankings emphasize financial outcomes, selectivity, and prestige
                in their criteria. Thus, colleges which funnel students from privileged backgrounds into
                select industries such as technology and financial services rise to the top.
            </p>
            <p>
                In order to develop a college ranking which emphasizes educational quality and accessibility,
                I accessed data from the National Center for Education Statistics. In particular, I attempted to track
                quality of faculty, financial support, and diversity, 
                reflecting the belief that the best college is the college which challenges and educates students,
                and provides them the tools to pursue whichever career they choose&#8212; regardless of how lucrative that
                chosen path may be.
            </p>
            <p>
                Ultimately, I ranked 2727 United States colleges and universities on graduation rates, graduation rates for
                Pell recipients, proportion of faculty with tenure or tenure-track positions, average amount of grant-aid awarded,
                average net price, and student body diversity. I gave the most weight to measures of academic environment (including
                graduation rates and stability of faculty), followed by financial accessibility (measured by the average grant-aid awarded
                and net price) and, lastly, student body diversity. 
            </p>
            <p>
                I used the statistical programming language R to analyze and organize the data. 
            </p>
            <p>
                The top 25 colleges are ranked below, with their associated scores (lower is better).&nbsp;
                <ToggleElement onElemClick={onElemClick} show={show}>
                    <img src={require('./media/CollegeRankings.png')} alt='list of rankings' id='rankings'></img>
                </ToggleElement>
            </p>
        </div>
    )
}

function ToggleElement( {children, onElemClick, show} ) {
    if (show) {
        return (
            <>
                <a onClick={onElemClick}>Click here to view the rankings.</a>
            </>
        );
    } else {
        return  (
            <>
                <a onClick={onElemClick}>Click here to hide the rankings.</a>
                {children}
            </>
        );
    }
}


export function RSA () {
    return (
        <div>
            <Header title='RSA Encryption with Haskell' source_code='https://github.com/NicoSantamaria/RSA-Encryption' />
            <p>
                Public-key cryptography systems allow for an encrypted message to be sent publically while the original, unencrypted
                message can only be safely recovered by the intended recipient. In a typical public key cryptosystem, the
                recipient generates a private key, then publishes a public key derived from the private key. The sender encrypts
                his private message with the public key, then publishes the encrypted key. Finally, the recipient decrypts the 
                public message with the private key known only to her.
            </p>
            <p>
                The strength of a public-key cryptosystem relies on the presumed difficulty of recovering the secret private key from
                the public key. To that end, RSA relies on classical computers' difficulty factoring large prime numbers over 
                a modular field. 
            </p>
            <p>
                The RSA Encryption project implements the underlying mathematics of RSA, which draws heavily from number theory,
                with Haskell. Haskell is a beautiful, type-safe, purely functional programming language. 
            </p>
        </div>
    )
}


export function NicoSantamaria () {
    return (
        <div>
            <Header title='Nico-Santamaria.com' source_code='https://github.com/NicoSantamaria/PersonalWebsite' />
            <p>
                Nico-Santamaria.com was built from scratch using the React framework, including the interactive
                navigation bar (left) with functional dropdown menus. The highly modular structure of the site
                allows for new posts and pages to be added with minimal effort. 
            </p>
        </div>
    )
}

export function SyndromeDecoder() {
    // Add handleClick function for logic on display
    // LinearCode and MatrixMod components

    return (
        <div>
            <Header title='Syndrome Decoding Module for Python' source_code='https://github.com/NicoSantamaria/SyndromeDecoder' />
            <p>
                How can you decipher
                a message once errors have been introduced into it? In particular, if a
                message is transmitted through an imperfect channel &#8212; that is, a channel
                with a non-zero probability of introducing errors into the message &#8212; how can
                the original message be safely recovered?
            </p>
            <p> 
                Most well-known, effective codes are linear codes. Linear codes are vector spaces over a finite field, 
                where messages 
                can be expressed as individual vectors. When an erroneous message is received, the goal is
                to decode the message to the "nearest" codeword within the vector space, with respect
                to a metric. 
                Syndrome decoding is a technique that allows for messages to be decoded
                with minimal storage space required after initial computations, harnessing the fact that
                many vectors within a vector space belong to the the same cosets within that vector space,
                and those cosets can be represented by computationally inexpensive syndromes. 
            </p>
            <p>
                In Syndrome Decoder, the Python module MatrixMod.py handles the background mathematics
                (linear algebra operations over a finite field), while LinearCode.py performs the syndrome
                decoding algorithm. Syndrome Decoder is a Python program which uses syndrome decoding to 
                correct for errors in linear codes. 
            </p>
            <p>
                For more on syndrome decoding, linear codes, and coding theory more generally, see: 
                <br></br>
                <cite>Hill, Raymond (1986). A first course in coding theory. Oxford Applied Mathematics and Computing 
                Science Series. Oxford University Press. ISBN 978-0-19-853803-5.</cite>
            </p>
        </div>
    );
}


export function NimPlayer() {
    return (
        <div>
            <Header title='Nim AI Player' source_code='https://github.com/NicoSantamaria/NimPlayer' />
            <p>
                Nim is a two-player strategy game where players take turn removing objects from distinct stacks. 
                Each turn,the player must remove at least one object, and can remove objects from only one stack
                 at a time. The player wins when her opponent is forced to take the last remaining object(s).
            </p>
            <p>
                Nim has been mathematically solved. For a player in a winning position at any point in the game, 
                there is an optimal strategy which guarantees victory for that player. The winning strategy is 
                to express the number of objects in each stack in binary, find the sum of the binary representations 
                as vectors over the finite field of order two, and choose the move which makes the resultant sum 
                equal to zero. 
            </p>
            <p>
                Nim Player allows the user to play Nim against an AI player using the mathematical optimal strategy
                or an AI player using a search algorithm to find winning game states. 
            </p>
        </div>
    )
}


export function ProjectEuler () {
    return (
        <div>
            <Header title='Project Euler' source_code='https://github.com/NicoSantamaria/ProjectEuler' />
            <p>
                <a href='https://projecteuler.net/about'>ProjectEuler.net</a> presents a series of puzzles, 
                mostly combinatorial in nature, which require 
                both mathematical insight and the use of computer programming to solve. The problems can be
                viewed <a href='https://projecteuler.net/archives'>here</a>. My collected Python solutions
                can be found on my GitHub (link above). 
            </p>
        </div>
    );
}


function Header({ title, source_code }) {
    return (
        <>
            <h1>{title}</h1>
            <p class='source_code'>
            View the source code <a href={source_code}>here</a>.
            </p>
        </>
    )
}
