import './pages/App.css';
import {
    Home,
    Introduction,
    AboutMe
} from './pages/Home.js';
import {
    Portfolio,
    SyndromeDecoder,
    ProjectEuler,
    NicoSantamaria,
    NimPlayer,
    RSA, 
    CollegeRankings
} from './pages/Portfolio.js';
import { CurrentProjects } from './pages/CurrentProjects.js';
import { Blog } from './pages/Blog.js'
  
  
export function DropdownMenu ( {value, handleClick} ) {
    if (value == 'Home') {
        return (
            <>
                <DropdownList value='About Me' onElemClick={() => handleClick('About Me')} />
            </>
      );
    } else if (value == 'Portfolio') {
        return (
            <>
                <DropdownList value='College Rankings' onElemClick={() => handleClick('College Rankings')}/>
                <DropdownList value='RSA Encryption' onElemClick={() => handleClick('RSA Encryption')} />
                <DropdownList value='Nico Santamaria' onElemClick={() => handleClick('Nico Santamaria')} />
                <DropdownList value='Syndrome Decoder' onElemClick={() => handleClick('Syndrome Decoder')} />
                <DropdownList value='Nim Player' onElemClick={() => handleClick('Nim Player')} />
                <DropdownList value='Project Euler' onElemClick={() => handleClick('Project Euler')} />
            </>
        );
    } else if (value == 'Current Projects') {
        return (
            <>
            </>
        );
    } else if (value == 'Blog') {
        return (
            <>
            </>
        );
    }
  }
  
  
function DropdownList( {value, onElemClick} ) {
    return (
        <div class='dropdown' onClick={onElemClick}>
            <b>{value}</b>
        </div>
    );
  }
  
  
export function ListElement({ selected, value, onElemClick, handleClick}) {
    if (selected) {
        return (
            <div class='list_element selected'>
                <h1 onClick={onElemClick}>{value}</h1>
                <DropdownMenu value={value} handleClick={handleClick} />
            </div>
        )
    } else {
        return (
            <div class='list_element' onClick={onElemClick}>
                <h1>{value}</h1>
            </div>
        );
    }
}

export function ChoosePage({ page_name, handleClickShow, show }) {
    if (page_name == 'Portfolio') {
        return <Portfolio onElemClick={() => handleClickShow()} show={show} />
    } else if (page_name == 'Current Projects') {
        return <CurrentProjects />
    } else if (page_name == 'Blog') {
        return <Blog />
    } else if (page_name == 'Introduction') {
        return <Introduction />
    } else if (page_name == 'About Me') {
        return <AboutMe />
    } else if (page_name == 'RSA Encryption') {
        return <RSA />
    } else if (page_name == 'College Rankings') {
        return <CollegeRankings onElemClick={() => handleClickShow()} show={show} />
    } else if (page_name == 'Syndrome Decoder') {
        return <SyndromeDecoder />
    } else if (page_name == 'Project Euler') {
        return <ProjectEuler />
    } else if (page_name == 'Nico Santamaria') {
        return <NicoSantamaria />
    } else if (page_name == 'Nim Player') {
        return <NimPlayer />
    } else {
        return <Home />
    }
}