import './App.css';
  

export function Blog() {
    return (
        <>
            {/* <SpanishSchedule /> */}
            {/* <MathEgo /> */}
            {/* <MathMe /> */}
            {/* <ProductivityManifesto /> */}
            <SkeletonComplete />
            <Inauguration />
        </>
    );
}


function BlogPost({ title, date}) {
    return (
        <>
            <h1>{ title }</h1>
            <p class='source_code'>{date}</p>
        </>
    );
}

function SpanishSchedule() {
    return (
        <div>
            <BlogPost title='My Spanish Studying Plan' date='December 29, 2023' />
            <p>
                Intro paragraph stuff. What am I doing? Why? Emphasize focus on process.
            </p>
            <h3>DuoLingo</h3>
            <p>
                description of duolingo, why I'm using it
            </p>
            <h3>Listening</h3>
            <p>
                Purpose of listening, what I'm listening to
            </p>
            <h3>Reading</h3>
            <p>
                Purpose of reading, my book list
            </p>
            <p>
                <ul>
                    <li><i>La muerte de Artemio Cruz,</i> Carlos Fuentues.</li>
                    <li><i>Cien años de soledad,</i> Gabriel García Márquez.</li>
                    <li><i>Boquitas pintadas, </i> Manuel Puig.</li>
                </ul>
            </p>
        </div>
    );
}

function CollegeRankingsPost () {
    return (
        <div>
            <BlogPost title='Analysis of the College Rankings' date='January 3, 2024' />
            <p>
                Analysis of the results. 
            </p>
        </div>
    )
}


function MathEgo() {
    return (
        <div>
            <BlogPost title='Mathematics and Ego' date ='December 26, 2023' />
            <p>
                A math problem feels nothing towards you. And yet, facing it, you feel things. 
                It may make you feel triumphant, having conquered it&#8212; it may make you feel <i>powerful</i>. You
                may swell with pride and pound your chest, victorious over the thing which does not fight back. 
                More often then not, it may make you feel frustrated, stuck. At times, you may even feel bored, tired 
                of attacking the thing from every possible angle, the thing which never defends itself, has no drive 
                towards self-preservation. It may make you feel small, and stupid.
            </p>
            <p>
                Doing mathematics means choosing to face that cold mirror everyday. Professor Ghassan 
                Sarkis at Pomona College first brought to my awareness this introspective power of mathematics: the
                capacity to awaken deep emotions about oneself, one's abilities, and one's intelligence, and to ensure
                you that these feelings come from within, not from the problems, because the problems feel nothing for
                you.
            </p>
            <p>
                The most deeply pleasurable experiences I have had with mathematics fall broadly into two categories. The
                first category captures the moments where I have been able to catch glimpses, however fleeting, of the
                magnificent hidden structures of mathematics &#8212; moments where I have been stunned by the universality, elegance,
                pure unaldulerated <i>mystery</i> at its core. I remember Professor Dwight Williams (then at Pomona, but 
                now at Morgan State University) demonstrating examples of groups of order eight in Abstract Algebra. The groups
                he presented were all of wildly different natures, composed over sets including multiples of <i>i</i>, post-it notes,
                and continuous functions. But then he revealed, in a wonderful magic trick of mathematical exposition, 
                that not only are these diverse groups structurally quite similar to each other, but <i>every possible group</i> of
                order eight is meaningfully equivalent to one of the handful that he had shown us. And so I peeked behind the
                curtains at the secret interworkings of mathematics.  
            </p>
            <p>
                Or, I remember Professor Shahriar Shahriari (in Combinatorial Mathematics, also at Pomona College) proving before
                the class the number of derangements of <i>n</i> &#8212; equivalent to the number of ways <i>n</i> people
                can trade hats, no person ending with the hat she started with. He proved that, in a random rearrangement
                of hats, the probability that no person ends with the hat she started with converges very quickly 
                to 1/<i>e</i> as <i>n</i> increases. Yes, that's right: <i>e</i>, an irrational number which, intuitively, has no place in
                a discussion of discrete numbers of combinations.
            </p>
            <p>
                These experiences are innocent. When people ask why I like math, they are what I cite. But the other category &#8212; 
                and I cannot deny that it is there &#8212; is purely ego. These other experiences are barbaric victories over math:
                challenging proofs finally wrapped up, tricky questions unraveled in an exhilarating moment of insight, and problem sets
                submitted with the t's crossed and the i's dotted. These are the moments where I haved looked into the mirror of mathematics 
                and said, <i>"I am smart! I am brilliant! I am talented!"</i>
            </p>
            <p>
                There are other shameful pleasures of studying mathematics. There is the pleasure (not for me! I am too pure and kind and naive
                for these sins!) of answering the ubiquitous question &#8212; <i>"So, what are you majoring in?"</i> &#8212; and watching, with your 
                response, your interlocutor's face as they work through the implications of what that means about you, your intelligence,
                your academic drive. There is the dirty feeling of pride when speaking with other math people in that delightfully arcane jargon
                of math, the feeling something close to belonging to an exclusive club whose members are sophisticated and worldly. 
            </p>
            <p>
                The moments are all the more precious and sweet because of how punishing mathematics can be. Studying math challenges you at the
                boundaries of your abilities every single day: it can be, on the one hand, deeply rewarrding; but, often, it is also simply exhausting,
                exhausting to be reminded of your limitations so persistently, beat down perpetually by a foe which doesn't care for you in the
                slightest, does not even do you the dignity of learning your name. 
            </p>
            <p>
                But as I continue studying math, gradually handing over more and more of my life to it, I'm trying to be careful to connect
                more with the first category of experience, the category borne of curiosity and wonder, childlike, almost, rather than the
                ego-fueled pleasure of the second category. Looking into the mirror of mathematics, I want to see, reflected back at me,
                a person motivated by beauty rather than conceit. 
            </p>
        </div>
    )
}


function MathMe () {
    return (
        <div>
            <BlogPost title='What Math Means to Me' date='December 8, 2023' />
            <p>
                the general point being that math really is something which I can return to which is comforting for me,
                and since there is so much ego and self-worth and posturing tied up with mathematical ability, it's taken 
                a long time for me to come to terms with the fact that my love for math is genuine, it is intellectually
                and emotionally fulfiling for me.  
            </p>
            <p>
                On ego: Ghassan Sarkis, a math problem says nothing on itself, your reaction to it only reveals things that
                were already inside you.
            </p>
        </div>
    )
}


function ProductivityManifesto () {
    return (
        <div>
            <BlogPost title='Productivity Manifesto' date='December 3, 2023' />
            <h3><i>Introduction</i></h3>
            <p>
                Over the last two years, thanks to personal revelations and lots of introspection, my perspective
                towards learning, productivity, and growth has shifted dramatically. In particular, I've interrogated
                the endeavors at which I have been successful and those at which I have fallen short throughout my life,
                and tried to reconstruct
                the reasons why. In doing so, I have come to believe that our dominant cultural ideas about goal-oriented efforts
                and productivity are misguided, unempathetic, and even downright self-defeating. Instead, I have gone in search
                of a new framework within which to reorient my relationship to work, one which acknowledges the differences among us
                while honoring our common humanity, and which combines ambition with self-compassion.
            </p>
            <p>
                I don't pretend to know what's right for you. But I do hope that my arguments below will inspire you to reflect on
                and challenge your own beliefs about productivity and growth. Too often, our beliefs are inherited unconsciously 
                from our culture or influences and remain imbedded in us, unchallenged, simply because we never thought to look.
            </p>
            <p>
                Ultimately, I hope to dissolve the cultural framework which puts ambition, hard work, and success in apparent 
                contradiction to an enjoyable and personally satisfying life. 
            </p>
            <h3><i>The Problem with Goals</i></h3>
            <p>
                The dominant cultural narrative is about setting, working towards, and achieving goals. This is the narrative I wish
                to challenge and replace.
            </p>
            <p>
                Setting yourslf up for failure, never enough. Sets you up for feeling like a failure. The twisted paradox is That
                feeling that you are a failure often sets you up to become one (see effects of low self-esteem).
            </p>
            <p>
                When chasing goals, you spent the vast majority of your time short of them, by definition. Then, if you do achieve a
                goal, you must set a new one right away, or risk stagnation. Even in those brief moments where you can celebrate yourself,
                your self-acceptance was conditional, namely, conditional on achieving the goal.
            </p>
            <p>
                Also sets you up for comparing yourself with others.
            </p>
            <h3><i>Reasons</i></h3>
            <p>
                Move from goals to reasons, shift metrics of success from predetermined milestones to time invested. The
                pace at which you learn is out of your hands, so celebrate yourself for the effort invested rather than
                the achievements profited. Ex of Goal (of studying mathematics): I want to become a stronger, more logical
                critical thinker (this one's particularly bad because, by the way it is constructed, it is a goal which can
                never truly be achieved). Instead, reason (for studying mathematics): Mathematics is fascinatingly deep
                and I find the hidden structures within beautiful in their elegance and universality. A goal is "of", a reason
                is "for": namely, a goal is the negation of a motivation, it draws from the endeavor; a reason is positive,
                and gives a justification for the endeavor. 
            </p>
            <p>
                You may struggle to come up with reasons for doing something in its own right. That's a good indication you 
                aren't truly passionate about the thing in the first place. As a result, motivating with reasons naturally
                directs you away from endeavors fueled by ego, and towards those which are born of genuine passion. 
            </p>
            <p>
                Privileging goals means you may give up if you don't succeed in exactly the way you had imagined for yourself,
                and remain blind to new, more profitable values. Privileging process allows you to succeed creatively, in ways
                you would have never imagined from the outset (especially becuase you don't even understand anything from the outset,
                such as which outcomes are feasible or even desirable!). Ex: With music, I never played 
            </p>
            <h3><i>Trust the Process (or, Time Spent)</i></h3>
            <p>
                The hardest part of shifting from a goal-oriented mindset towards one concerned with reasons and process is trusting
                that your processes will bear fruit. Trusting the process means believing that accomplishments will spring naturally
                from your effot in the future, accomplishments which you cannot, nor should, predict in the present day. 
                But the the switch is not purely a leap of faith. Consider all the things you are capable of doing right now, in the present
                moment. Chances are, very few of them are skills you acquired through conscious, goal-oriented effort; the vast majority
                of things we learn to do, we have learned simply by doing. 
            </p>
            <p>
                Should also argue why this way of thinking will yield better concrete results in the long term, despite not seeming that
                way at first glance.
            </p>
            <h3><i>Conclusion</i></h3>
            <p>
                Goal oriented thinking is an anxious and self-defeated way of thinking. Trusting yourself and your processes is scary,
                and requires creativity and open-mindedness about your future. But by conquering the anxiety, you set yourself up
                for a life that is simultaneously more productive and more fulfilling.
            </p>
        </div>
    );
}


function SkeletonComplete () {
    return (
        <div>
            <BlogPost title='Skeleton Complete' date='November 29, 2023' />
            <p>
                Today, I finished the general skeleton of the website. In particular, I completed the subheadings
                on the left navigation bar, which will direct to noteworthy projects or blog posts in the future.
                Getting them up and running was a challenge and I learned a lot about passing props and components
                in React in the process, as well as a fair amount about JavaScript syntax and functionality more 
                generally.
            </p>
            <p>
                Now that the skeleton of the site is working, I'm going to shift my focus to contributing meaningful
                content (though some minor bugs remain with the navigation bar). Most immediately, that will include 
                a webscraper that tests whether any given Wikipedia page
                routes to the philosophy page, which is something of an urban legend. I'm also working on a Python library
                for syndrome decoding and linear algebra over finite fields, though that will take longer to make its way
                over to the site.  
            </p>
            <p>
                Lastly, I'm going to work out how best to host the site on the web. And finish my finals. 
            </p>
        </div>
    );
}


function Inauguration () {
    return (
        <div>
            <BlogPost title='Inauguration' date='November 28, 2023' />
            <p>
                The website is up and running (locally)! A couple of days ago, I took
                it upon myself to build a personal website from the most fundamental
                tools I could manage, in order to challenge my engineering skills and
                showcase some of what I've been working on. 
            </p>
            <p>
                I ended up choosing to learn React.js. I think that ended up being a good decision,
                though it gave me something of a headache at the beginning. In particular,
                I struggled to get the sidebar up and running. But, now that I've finally figured
                out how to navigate the mess of props and components, which were much less intuitive
                to me than coding in Python or Haskell, I'm quite happy with how things turned out.
            </p>
            <p> 
                The project afforded me an unexpected opportunity to review JavaScript, HTML, and CSS, which
                I used to play around with in high school but had almost entirely forgotten.
            </p>
            <p>
                There's still much work to be done -- for now, the site consists of a navigation bar directing
                to four mostly empty pages -- but I think I've gotten a good start and I'm excited to keep
                building the site as time goes on.
            </p>
        </div>
    );
}